<template>
	<div class="card card-custom card-stretch gutter-b">
		<div v-if="!streamId">
			<b-alert variant="info" show>
				<h5>This widget has not been configured for a video stream.</h5>
				Please set the stream by editing the widget properties in the dashboard layout section of the admin accounts panel
			</b-alert>
		</div>
		<div v-else>
			<VideoPlayer v-if="stream.ant_id" :name="stream.stream_name" :antId="stream.ant_id" :jwt="stream.jwt" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'DashVideo',
	components: {
		VideoPlayer: () => import('@/view/content/widgets/video/VideoPlayer.vue'),
	},
	props: {
		streamId: {
			type: Number,
		},
	},
	data() {
		return {
			loaded: false,
			stream: {
				id: null,
				ant_id: null,
				stream_name: null,
				jwt: null,
			},
		};
	},
	created() {
		this.loaded = false;
		this.$http.get(`stream/${this.streamId}`).then(({ data }) => {
			this.stream = data;
			this.loaded = true;
		});
	},
};
</script>

<style></style>
